<template>
	<Metier name="cadre-de-vie" title="Cadre de Vie" :main-menu="[{title: 'Technicien CDV', missions: ['Assure la gestion des contrats (entretien des locaux, manutention, petits approvisionnements, sûreté…)', 'Contribue à l\'amélioration du cadre de vie des salariés', 'Gère le budget du service'], competences: ['Connaissances en gestion et comptabilité', 'Connaissance du marché et des entreprises intervenantes', 'Maîtrise des outils informatiques'], qualites: ['Sens relationnel et de la négociation', 'Organisation', 'Esprit d\'initiative']}, {title: 'Agent Technique CDV ', missions: ['Assure la gestion des contrats du parc immobilier et véhicule de la plateforme', 'Participe aux autres missions du service'], competences: ['Connaissances en gestion et comptabilité', 'Connaissance du marché et des entreprises intervenantes', 'Maîtrise des outils informatiques'], qualites: ['Sens relationnel et de la négociation', 'Organisation', 'Esprit d\'initiative']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
